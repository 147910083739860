import { ReactComponent as JavaScriptIcon } from "../lib/icons/JavaScript.svg";
import { ReactComponent as HtmlIcon } from "../lib/icons/Html.svg";
import { ReactComponent as ReactjsIcon } from "../lib/icons/ReactJS.svg";
import { ReactComponent as WordPressIcon } from "../lib/icons/WordPress.svg";
import { ReactComponent as PHPIcon } from "../lib/icons/PHP.svg";
import { ReactComponent as SymfonyIcon } from "../lib/icons/Symfony.svg";
import { ReactComponent as DrupalIcon } from "../lib/icons/Drupal.svg";

export const skills = [
  {
    skillBox: "pt-10 sm:pb-5 sm:pt-10 sm:pl-10",
    skillIcon: <PHPIcon className="h-10 w-10"></PHPIcon>,
    skillTitle: "PHP",
    skillDesc: "Drawing from over eight years of PHP expertise.",
    skillPercent: "90%",
  },
  {
    skillBox: "pb-10 sm:pt-5 sm:pb-10 sm:pr-10 border-r-0 sm:border-r-[1px] border-b-[1px] border-[#9bb1cf]",
    skillIcon: <JavaScriptIcon className="h-10 w-10"></JavaScriptIcon>,
    skillTitle: "JavaScript",
    skillDesc: "Bringing creativity to life, even without expert status.",
    skillPercent: "70%",
  },
  {
    skillBox: "pb-10 pt-10 sm:pt-5 sm:pb-10 sm:pl-10 border-b-[1px] border-[#9bb1cf]",
    skillIcon: <HtmlIcon className="h-10 w-10"></HtmlIcon>,
    skillTitle: "HTML/CSS3",
    skillDesc: "This comes naturally to me.",
    skillPercent: "100%",
  },
  {
    skillBox: "pb-10 pt-10 sm:pb-5 sm:pt-10 sm:pr-10 border-r-0 sm:border-r-[1px] sm:border-b-0 border-b-[1px] border-[#9bb1cf]",
    skillIcon: <DrupalIcon className="h-10 w-10"></DrupalIcon>,
    skillTitle: "Drupal",
    skillDesc: "Mastering Drupal for over four years, spanning D7, D8/9, and now D10!",
    skillPercent: "90%",
  },
  {
    skillBox: "pt-10 sm:pb-5 sm:pt-10 sm:pl-10",
    skillIcon: <WordPressIcon className="h-10 w-10"></WordPressIcon>,
    skillTitle: "WordPress",
    skillDesc: "Continuously honing my skills with best practices while successfully launching and maintaining several websites.",
    skillPercent: "70%",
  },
  {
    skillBox: "pt-10 sm:pb-5 sm:pt-10 sm:pl-10",
    skillIcon: <SymfonyIcon className="h-10 w-10"></SymfonyIcon>,
    skillTitle: "Symfony",
    skillDesc: "Harnessing the power of the Symfony framework since 2018, with an insatiable thirst for ongoing learning and growth.",
    skillPercent: "80%",
  },
  {
    skillBox: "pt-10 sm:pb-5 sm:pt-10 sm:pl-10",
    skillIcon: <ReactjsIcon className="h-10 w-10"></ReactjsIcon>,
    skillTitle: "ReactJS",
    skillDesc: "Embarking on an exciting journey with React, immersing myself in intensive learning and leveraging APIs to build transformative applications.",
    skillPercent: "65%",
  },
];
