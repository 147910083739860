import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Header } from "../../components/Header";
import { FeaturedArea } from "../../components/FeaturedArea";
import { Analytics } from '@vercel/analytics/react';
// import { AboutUs } from "../../components/AboutUs";
import { Services, Services2 } from "../../components/Services";
// import { MyWorkExperience } from "../../components/MyWorkExperience";
// import { EducationAndSkills } from "../../components/EducationAndSkills";
// import { Portfolio } from "../../components/Portfolio";
// import { FreelanceWork } from "../../components/FreelanceWork";
// import { Testimonials } from "../../components/Testimonials";
// import { BlogPost } from "../../components/BlogPosts";
// import { Partners } from "../../components/Partners";
// import { ContactInfoProfiles } from "../../components/ContactInfoProfiles";
import { ContactUs } from "../../components/ContactUs";
import { BottomBar } from "../../components/BottomBar";
import { Education2 } from "../../components/Education";
import { MyExperienceAndSkills } from "../../components/MyExperienceAndSkills";

const HomePage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Home | Juanlu Blanco</title>
      </Helmet>
      <Header></Header>
      <FeaturedArea></FeaturedArea>
      {/* <AboutUs></AboutUs> */}
      {/* <Services></Services> */}
      {/* <MyWorkExperience></MyWorkExperience> */}
      <Services2></Services2>
      <MyExperienceAndSkills></MyExperienceAndSkills>
      <Education2></Education2>
      {/* <Portfolio></Portfolio> */}
      {/* <FreelanceWork></FreelanceWork> */}
      {/* <Testimonials></Testimonials> */}
      {/* <BlogPost></BlogPost> */}
      {/* <Partners></Partners> */}
      {/* <ContactInfoProfiles></ContactInfoProfiles> */}
      <ContactUs></ContactUs>
      <BottomBar></BottomBar>
      <Analytics />
    </Fragment>
  );
};

export default HomePage;
