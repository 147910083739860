import ExpImg1 from "../assets/images/resources/expImg1.jpg";
import ExpImg2 from "../assets/images/resources/expImg2.jpg";
import ExpImg3 from "../assets/images/resources/expImg3.jpg";
// import ExpImg4 from "../assets/images/resources/expImg4.jpg";

export const workExp = [
  {
    expCount: "1",
    expDate: "2022 - 2023",
    expImg: ExpImg1,
    expTitle: "Drupal Data Migration",
    expSubTitle: "Migrating from Drupal 7 to Drupal 9",
    expDesc:
      "I have executed multiple data migrations from Drupal 7 to Drupal 9. With meticulous attention to detail and a commitment.",
  },
  {
    expCount: "2",
    expDate: "2018 - 2022",
    expImg: ExpImg2,
    expTitle: "CI/CD Implementation",
    expSubTitle: "Pantheon, Jenkins",
    expDesc:
      "I have implemented Continuous Integration and Continuous Delivery (CI/CD) pipelines using tools such as Pantheon, GitHub Actions, Jenkins, CircleCi... By automating the software development processes, I have enhanced efficiency and productivity, enabling seamless deployments and streamlined operations.",
  },
  {
    expCount: "3",
    expDate: "2019 - 2019",
    expImg: ExpImg3,
    expTitle: "Python Developer",
    expSubTitle: "Embracing a New Challenge in Python",
    expDesc:
      "As a developer, I embraced the opportunity to work with Python and successfully delivered multiple applications for a specific project. Despite being relatively new to the language, I quickly adapted and leveraged my problem-solving skills to ensure the successful development and launch of these applications.",
  },
];
