import { ReactComponent as FacebookIcon } from "../lib/icons/Facebook.svg";
import { ReactComponent as TwitterIcon } from "../lib/icons/Twitter.svg";
import { ReactComponent as LinkedinIcon } from "../lib/icons/Linkedin.svg";

export const social4 = [
  {
    socialIcon: (
      <FacebookIcon className="h-5 w-5 lg:h-10 lg:w-10 fill-[#006EE4] hover:fill-accent"></FacebookIcon>
    ),
    socialLink: "https://www.facebook.com/juanluis.blancorodriguez",
    socialTitle: "Facebook",
  },
  {
    socialIcon: (
      <LinkedinIcon className="h-5 w-5 lg:h-10 lg:w-10 fill-[#005AB8] hover:fill-accent"></LinkedinIcon>
    ),
    socialLink: "https://www.linkedin.com/in/juanlu-blanco-rodriguez",
    socialTitle: "Linkedin",
  },
  {
    socialIcon: (
      <TwitterIcon className="h-5 w-5 lg:h-10 lg:w-10 fill-[#008FEB] hover:fill-accent"></TwitterIcon>
    ),
    socialLink: "https://twitter.com/jlblanco12",
    socialTitle: "Twitter",
  },
];
