export const education = [
  {
    eduDate: "2021 - 2021",
    eduTitle: "Scrum Master Cert.",
    eduIns: "AIBES",
    eduDesc: "OCM certificate holders prove that they have an understanding of the underlying principles of Scrum and can effectively apply Scrum in complex, real-world.",
  },
  {
    eduDate: "2021 - 2021",
    eduTitle: "Scrum Fundamentals Cert.",
    eduIns: "AIBES",
    eduDesc: "SF certificate holders prove that they have an understanding of the underlying fundamentals of Scrum.",
  },
  {
    eduDate: "2018 - 2018",
    eduTitle: "Master in Drupal 8",
    eduIns: "Forcontu",
    eduDesc: "Achieved an advanced level of proficiency in Drupal 8, the latest version of the popular content management system. Acquired in-depth knowledge of Drupal 8's architecture, advanced features, and development techniques.",
  },
  {
    eduDate: "2018 - 2018",
    eduTitle: "Expert in Drupal 7",
    eduIns: "Forcontu",
    eduDesc: "Acquired in-depth knowledge and skills in developing and managing websites using Drupal 7, a powerful content management system. Mastered Drupal 7's features, modules, and best practices to deliver high-quality and customizable web solutions.",
  },
  {
    eduDate: "2016 - 2016",
    eduTitle: "Development with Symfony3",
    eduIns: "KNP Labs",
    eduDesc: "Acquired specialized training in Symfony3, a popular PHP framework, from KNP Labs. Developed skills in building robust and scalable web applications using Symfony3's advanced features and best practices",
  },
  {
    eduDate: "2010 - 2014",
    eduTitle: "Bachelor in Computer Systems Engineering",
    eduIns: "Cádiz University",
    eduDesc: "Comprehensive program covering hardware, software, networking, and engineering principles. Developed expertise in computer architecture, operating systems, databases, and programming. Strong foundation for addressing complex technological challenges.",
  },
  {
    eduDate: "2010 - 2012",
    eduTitle: "Degree in Computer Science",
    eduIns: "Wroclaw University",
    eduDesc: "Developed a strong foundation in various aspects of computer science, including programming, algorithms, data structures, and software development methodologies.",
  },
];
